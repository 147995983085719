@font-face {
  font-family: "Muli";
  src: url("/fonts/Muli.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MuliItalic";
  src: url("/fonts/Muli-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "MuliBold";
  src: url("/fonts/Muli-Bold.ttf");
  font-weight: normal;
  font-style: 800;
}

@font-face {
  font-family: "PoppinsRegular";
  src: url("/fonts/Poppins-Regular.ttf");
  font-weight: normal;
  font-style: 800;
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: url("/fonts/Poppins-SemiBold.ttf");
  font-weight: normal;
  font-style: bold;
}

* {
  text-rendering: optimizeLegibility;
}


@import 'custom';

@import '~bootstrap/scss/bootstrap';

// Overrides



@include media-breakpoint-down(md){
  .btn-group {
    display: flex;
    flex-direction: column;
    .btn {
      margin-top: -1px;
      margin-left: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .btn-block-sm {
    display: block;
    width: 100%;
  }
}
